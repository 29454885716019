.max-size {
    max-width: 1280px;
}

.rakete-rot {
    color: #bb0505;
}

.rakete-font {
    font-family: Kdam;
}

.font-count {
    font-family: 'Orbitron', sans-serif;
}

@font-face {
    font-family: 'Kdam';
    font-style: normal;
    src: local('KDam Regular'), local('Kdam-Regular'),
        url('/fonts/KdamThmorPro-Regular.ttf') format('truetype'),
}

.z1 {
    z-index: 1;
}

.z2 {
    z-index: 2;
}

body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

footer {
    margin-top: auto;
}


.bg-rakete {
    background-color: #bb0505;
}

.news-img {
    width: 100%;
    height: auto;
}

.news-card {
    min-width: 222px;
}

.team-card {
    min-width: 200px;
}

.button-overlay {
    position: relative;
    display: inline-block;
}

.button-overlay button {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.border-rakete {
    border-color: #bb0505;
}

/* TIMELINE */

.main-timeline {
    position: relative
}

.main-timeline:before {
    content: "";
    display: block;
    width: 3px;
    height: 100%;
    background: #53535359;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0
}

.main-timeline .timeline {
    margin-bottom: 40px;
    position: relative
}

.main-timeline .timeline:after {
    content: "";
    display: block;
    clear: both
}

.main-timeline .icon {
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin: auto;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    border: 3px solid #9c0f0f;
    border-radius: 50%;
}

.main-timeline .date-content {
    width: 50%;
    float: left;
    margin-top: 22px;
    position: relative
}

.main-timeline .date-content:before {
    content: "";
    width: 36.5%;
    height: 2px;
    background: #ffffff00;
    margin: auto 0;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0
}

.main-timeline .date-outer {
    width: 125px;
    height: 125px;
    font-size: 16px;
    text-align: center;
    margin: auto;
    z-index: 1;
    transition: transform 250ms;
}

.main-timeline .date-outer:before,
.main-timeline .date-outer:after {
    content: "";
    width: 125px;
    height: 125px;
    margin: 0 auto;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.date-outer:hover {
    transform: scale(1.1);
}
.main-timeline .date-outer:before {
    background: #ffffff;
    border: 4px solid #bb0505;

    left: -3px
}

.main-timeline .date-outer:after {

    border: 4px solid #232323;
    left: 3px
}

.main-timeline .date {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 27%;
    left: 0
}

.main-timeline .month {
    font-size: 18px;
    font-weight: 700
}

.main-timeline .year {
    display: block;
    font-size: 30px;
    font-weight: 700;
    color: #232323;
    line-height: 36px
}

.main-timeline .timeline-content {
    width: 50%;
    padding: 20px 0 20px 50px;
    float: right
}

.main-timeline .title {
    font-size: 19px;
    font-weight: 700;
    line-height: 24px;
    margin: 0 0 15px 0
}

.main-timeline .description {
    margin-bottom: 0
}

.main-timeline .timeline:nth-child(2n) .date-content {
    float: right
}

.main-timeline .timeline:nth-child(2n) .date-content:before {
    left: 10px
}

.main-timeline .timeline:nth-child(2n) .timeline-content {
    padding: 20px 50px 20px 0;
    text-align: right
}

@media only screen and (max-width: 991px) {
    .main-timeline .date-content {
        margin-top: 35px
    }

    .main-timeline .date-content:before {
        width: 22.5%
    }

    .main-timeline .timeline-content {
        padding: 10px 0 10px 30px
    }

    .main-timeline .title {
        font-size: 17px
    }

    .main-timeline .timeline:nth-child(2n) .timeline-content {
        padding: 10px 30px 10px 0
    }
}

@media only screen and (max-width: 767px) {
    .main-timeline:before {
        margin: 0;
        left: 7px
    }

    .main-timeline .timeline {
        margin-bottom: 20px
    }

    .main-timeline .timeline:last-child {
        margin-bottom: 0
    }

    .main-timeline .icon {
        margin: auto 0
    }

    .main-timeline .date-content {
        width: 95%;
        float: right;
        margin-top: 0
    }

    .main-timeline .date-content:before {
        display: none
    }

    .main-timeline .date-outer {
        width: 110px;
        height: 110px
    }

    .main-timeline .date-outer:before,
    .main-timeline .date-outer:after {
        width: 110px;
        height: 110px
    }

    .main-timeline .date {
        top: 30%
    }

    .main-timeline .year {
        font-size: 24px
    }

    .main-timeline .timeline-content,
    .main-timeline .timeline:nth-child(2n) .timeline-content {
        width: 95%;
        text-align: center;
        padding: 10px 0
    }

    .main-timeline .title {
        margin-bottom: 10px
    }
}


.icons {
    max-height: 150px;
}

.wrapper {
    position: fixed;
    position: fixed;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 345px;
    width: 100%;
    background-color: #ffffffea;
    border-radius: 8px;
    padding: 15px 25px 22px;
    transition: top 0.5s ease;
}

.wrapper.show {
    top: 50%;
}

.wrapper header {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

header i {
    color: #bb0505;
    font-size: 32px;
}

header h2 {
    color: #bb0505;
    font-weight: 500;
}

.wrapper .data p {
    color: #333;
    font-size: 16px;
}

.data p a {
    color: #bb0505;
    text-decoration: none;

}

.data p a:hover {
    text-decoration: underline;
}

.wrapper .buttons {
    margin-top: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttons .button {
    color: #fff;
    border: none;
    width: calc(100% / 3);
    padding: 8px 0;
    border-radius: 4px;
    background: #bb0505;
    transition: all 0.2s ease;
}

.buttons .button:hover {
    background-color: #db1010;
}

.blur {
    filter: blur(4px);
}

.adminnav {
    height: 68px;
}

.os {
    width: 71px;
    height: 40px;
}

@keyframes slide {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .logos {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
  }

.logos:before,
.logos:after {
    position: absolute;
    top: 0;
    width: 150px;
    height: 100%;
    content: "";
    z-index: 2;
}

.logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos:hover .logos-slide {
    animation-play-state: paused;
}

.logos-slide {
    display: inline-block;
    animation: 500s slide infinite linear;
    touch-action: pan-left;
    touch-action: pan-right;
}
.logos-slide.paused {
    animation-play-state: paused; /* Pausiert die Animation */
  }

.logos-slide img {
    max-height: 75px;
    max-width: 250px;
    width: auto;
    margin: 0 30px;
    cursor: pointer;
}

.logo {
    transition: transform 150ms;
    width: 50px;
}

.logo-footer {
    max-height: 150px;
    margin: 20px;
}

.logo:hover {
    transform: scale(1.1);
}

h1+p a:hover {
	text-decoration: none;
}

h2 span {
	margin-left: 1em;
	color: #aaa;
	font-size: 85%;
}
.column {
	margin: 15px 15px 0;
	padding: 0;
}
.column:last-child {
	padding-bottom: 60px;
}
.column::after {
	content: '';
	clear: both;
	display: block;
}
.column div {
	position: relative;
	float: left;
	width: 300px;
	height: 200px;
	margin: 0 0 0 25px;
	padding: 0;
}

.column div:first-child {
	margin-left: 0;
}
.column div span {
	position: absolute;
	bottom: -20px;
	left: 0;
	z-index: -1;
	display: block;
	width: 300px;
	margin: 0;
	padding: 0;
	color: #444;
	font-size: 18px;
	text-decoration: none;
	text-align: center;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
	opacity: 0;
}

figure {
	background: #fff;
	overflow: hidden;
}
figure:hover+span {
	bottom: -36px;
	opacity: 1;
}

.hover08 figure img {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.hover08 figure:hover img {
	-webkit-filter: grayscale(0);
	filter: grayscale(0);
}
.team-img {
    -webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.team-img:hover {
    -webkit-filter: grayscale(0);
	filter: grayscale(0);
}

.w-1000 {
    max-width: 1000px;
}

.mw-50 {
    min-width: 50px;
}

.shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.fs-7 {
    font-size: x-small;
}

.bgtrans {
    background-color: #ffffff00;
}

.closebutton {
    fill:rgba(255, 255, 255, 0.705)
    
}

.closebutton:hover {
    fill:rgb(255, 255, 255)
}

.brand {
    margin-left: -18px;
}

.border-rakete {
    border-bottom-color: #bb0505;
    border-bottom: #bb0505;
}

.navit {
    color: #1d1c1c;
    margin-left: 10px;
}
.navit:hover {
    color: #1d1c1c;
    font-weight: 500;
    background-color: #ffffff;

}

.news-max {
    max-width: 500px;
}

.nav-link:hover {
    color: #1d1c1c;
    font-weight: 500;
}

.navilinks {
    text-decoration: none;

}

.text-justify {
    text-align: justify;
}

#basic-navbar-nav a.active {
    color: #bb0505;
}

.dropdown-item.active {
    background-color: #ffffff00;
}

.sponsorwanted {
    width: 87px;
    height: 37px;
}

.signbutton {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 3px 3px;
}

.signbutton:hover {
    cursor: pointer;
    background: rgb(187,5,5);
    background: radial-gradient( rgba(230,69,29,1) 0%, rgba(187,5,5,1) 100%);
    color: #fff;
    box-shadow: rgba(252, 117, 117, 0.349) 0px 3px 3px;
}

.login-w {
    min-width: 400px;
}

.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.fs-7 {
    font-size: small;
}

.fs-8 {
    font-size: x-small;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    /* 100% der Bildschirmhöhe */
}

.login-button {
    max-width: 200px;
    padding: 20px;
    min-height: 39px;
    background: #b40202;
    padding-block: 7px;
    color: white;
    text-align: center;
}

.decline-button:hover {

    cursor: pointer;
    border-color: rgba(41, 41, 41, 0.616);
    color: rgba(41, 41, 41, 0.753);
}

.decline-button {
    max-width: 200px;
    padding: 20px;
    min-height: 39px;
    background: #ffffff;
    padding-block: 7px;
    color: rgba(128, 127, 127, 0.616);
    text-align: center;
    border-color: rgba(128, 127, 127, 0.616);
    border-style:solid;
}

.login-button:hover {

    cursor: pointer;
    background: #bb0505;
}


.logo-container {
    display: inline-block;
    /* Zeigt die Container nebeneinander an */
}

.logo-text {
    margin-left: -20px;
}

.logo-nav-text {
    margin-left: -16px;
}

.input-btn {
    font-size: small;
}

.align-right {
    display: flex;
    justify-content: center;
    align-self: center;
}

.hover {
    transition-duration: 250ms;
}

.hover:hover {
    transform: scale(1.01);
    cursor: pointer;
}

.newsline {
    transition-duration: 250ms;
    max-width: 1000px;
    caret-color: transparent;
}

.newsline:hover {
    transform: scale(1.01);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.151);
    cursor: pointer;
}

.icons {
    fill: rgb(110, 110, 110);
}

.icons:hover {
    transform: scale(1.2);
    cursor: pointer;
    fill: #bb0505;
}

.icon-edit {
    color: rgb(110, 110, 110);
}

.icon-edit:hover {
    transform: scale(1.2);
    cursor: pointer;
    color: #bb0505;
}

.m-w-5 {
    max-width: 750px;
}

.m-w-1 {
    width: 10px;
}

.no-text-cursor {
    caret-color: transparent;
}

.img-preview {
    max-width: 400px;
    max-height: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    width: 50%;

}

.modal .modal-footer {
    justify-content: center;
}

.gallery-max {
    max-width: 300px;
}


